<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">ปรับสถานะงาน</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>เรื่อง</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <div class="pt-3">{{ topic }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ประเภทงาน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <div class="pt-3">{{ jobTypeName }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>วันที่กำหนดเสร็จงาน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <div class="pt-3">{{ dueDate }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>รายละเอียด</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <div class="pt-3" v-html="description"></div>
                          <!-- <vue-editor v-model="description" :editor-toolbar="customToolbar" /> -->
                          <!-- <v-textarea
                            v-model="description"
                            auto-grow
                            outlined
                            rows="8"
                          ></v-textarea> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>แนบลิ้งค์ไฟล์เอกสาร</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <div class="pt-3">{{ linkFile }}</div>
                        </v-col>
                    </v-row>
                    
                </v-container>

            <v-card-title>
                <span class="subtitle-1">ปรับสถานะ</span>
            </v-card-title>
            <v-divider class="mx-4 mb-5" />
            <v-container class="body-2 pb-16">
              <v-divider class="mx-4 mb-5" />
              <v-row>
                  <v-col cols="3">
                      <v-subheader>สถานะงาน</v-subheader>
                  </v-col>
                  <v-col cols="5">
                      <validation-provider
                          v-slot="{ errors }"
                          name="assignStatus"
                          rules="required"
                      >
                        <v-select
                            :items="jobStatusItems"
                            v-model="assignStatus"
                            item-text="text"
                            item-value="value"
                            :error-messages="errors"
                            dense
                            outlined
                        ></v-select>
                      </validation-provider>
                  </v-col>
              </v-row>
              <v-row v-show="(jobStatus != '3')">
                  <v-col cols="3">
                      <v-subheader>วันที่ดำเนินการเสร็จ</v-subheader>
                  </v-col>
                  <v-col cols="5">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="closeDate"
                                label="วันที่"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="closeDate"
                            locale="th-th"
                            @input="menu = false"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
              </v-row>
              <v-row v-show="(jobStatus != '3')">
                  <v-col cols="3">
                    <v-subheader>เวลาที่ดำเนินการเสร็จ</v-subheader>
                  </v-col>
                  <v-col cols="5">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="closeTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="closeTime"
                          label="Picker in menu"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="closeTime"
                        full-width
                        format="24hr"
                        @click:minute="$refs.menu2.save(closeTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col>
                      <v-btn depressed color="success" @click="save()">
                          บันทึกปรับสถานะ
                      </v-btn>
                  </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['jobId'],
    data: () => ({
        storeUser: null,
        topic: '',
        dueDate: new Date().toISOString().substr(0, 10),
        closeDate: new Date().toISOString().substr(0, 10),
        closeTime: new Date().toTimeString().substr(0, 5),
        description: '',
        linkFile: '',
        jobType: null,
        jobTypeItems: [],
        jobStatus: 6,
        assignStatus: 6,
        jobStatusItems: [
          {
            text: 'รับทราบงาน',
            value: 6
          },
          {
            text: 'ดำเนินการเสร็จเรียบร้อย',
            value: 4
          },
          {
            text: 'ยกเลิกงาน',
            value: 5
          }
        ],
        formChkErrors: false,
        jobTypeName: '',
        jobParent: '',
        menu2: false,
        menu: false
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        async getData() {
          try {
            const res = (await Api().get('jobrequest/' + this.jobId)).data;

            console.log(res)

            if (res.status == 'success') {
              this.topic = res.data.topic
              this.jobType = res.data.job_type
              this.dueDate = res.data.due_date
              this.description = res.data.description
              this.linkFile = res.data.link_file
              this.jobTypeName = res.data.type_name
              this.jobParent = res.data.job_parent
              this.jobStatus = res.data.job_status

              if(this.jobStatus == '3') {
                this.assignStatus = 6
                this.jobStatusItems = [
                  {
                    text: 'รับทราบงาน',
                    value: 6
                  },
                ]
              }else{
                this.assignStatus = 4
                this.jobStatusItems = [
                  {
                    text: 'ดำเนินการเสร็จเรียบร้อย',
                    value: 4
                  },
                  {
                    text: 'ยกเลิกงาน',
                    value: 5
                  }
                ]
              }
            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        },
        async save() {
            this.$store.commit('setLoading', true)
            // console.log(this.closeTime)
            const req = {
                closed_date: this.closeDate,
                closed_time: this.closeTime,
                job_parent: this.jobParent,
                job_status: this.assignStatus
            }

            this.formChkErrors = false
            this.formChkErrors = await this.$refs.form.validate()

            if(this.formChkErrors){
              let res = (await Api().put("jobupdatestatus/" + this.jobId, req)).data;

              if (res.status == 'success') {
                  this.$store.commit('setLoading', false)
                  await this.$refs.confirm.open('ข้อความ', 'บันทึกปรับสถานะข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                  this.$router.push('/assign/list')
              }else{
                  this.$store.commit('setLoading', false)
              }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>